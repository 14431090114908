@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.image-container {
  background-color: var(--primary-bg-apps);
  font-family: 'Muli', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  padding: 3rem;
  overflow: hidden;
}

@media screen and (max-width: 920px) {
  .image-container {
    padding: 1.5rem;
  }
}
