.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  transition: opacity var(--transition-time) ease;
}

.modal--hidden {
  display: none;
  opacity: 0;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
}

.modal-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--secondary-bg-lib);
  width: 650px;
  height: 400px;
}

.modal-title {
  color: var(--primary-text-lib);
}
