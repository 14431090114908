.navbar {
  display: flex;
  justify-content: space-between;
  height: 75px;
  margin: 1rem 0;
  color: var(--primary-text);
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2rem;
  list-style: none;
}

.nav-item {
  padding: 0 1rem;
  transition: var(--scroll-time) ease all;
}

.nav-link {
  font-family: var(--secondary-font);
  font-size: 0.9rem;
  font-weight: 200;
  color: var(--primary-text);
  opacity: 1;
  transition: var(--transition-time) ease all;
}

.paranthesis {
  color: var(--primary-text);
  letter-spacing: normal;
  opacity: 0;
  transition: var(--transition-time) ease all;
}

.paranthesis-r {
  padding-left: 5px;
}

.nav-bullet {
  color: var(--highlight);
  letter-spacing: -2px;
  opacity: 1;
  transform: translateX(5px);
  transition: var(--transition-time) ease opacity;
}

.nav-btn {
  font-family: 'Consolas', 'Roboto Mono', monospace;
  font-size: 0.9rem;
  font-weight: 200;
  color: var(--highlight);
  opacity: 1;
  margin: 0 1rem 0 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--highlight);
  border-radius: 3px;
  transition: var(--transition-time) ease all;
}

.nav-btn a {
  color: var(--highlight);
}

.nav-link:hover {
  color: var(--highlight);
}

.nav-link:hover > .paranthesis {
  letter-spacing: 5px;
  opacity: 1;
}
.nav-link:hover > .nav-bullet {
  opacity: 0;
}

.btn:hover > .nav-btn {
  background-color: rgba(255, 147, 5, 0.2);
  transform: scale(100%);
}

/* ----- MOBILE HAMBURGER NAV ----- */
.mobile-navbar {
  position: relative;
  z-index: 1;
}

.mobile-navbar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  margin: 0;
}
.mobile-navbar .mobile-nav--menu ul {
  list-style: none;
}

.toggler {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  margin: 1rem;
  cursor: pointer;
  z-index: 3;
  opacity: 0;
}

.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 35px;
  height: 35px;
  margin: 1rem;
}

/* hamburger lines */
.hamburger > div {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: var(--highlight);
  display: flex;
  justify-content: right;
  align-items: center;
  transform: translateY(-10px);
  transition: all 0.25s ease;
}

.hamburger > div::before,
.hamburger > div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 10px;
  width: 80%;
  height: 2px;
  background: inherit;
}

.hamburger > div::after {
  top: 20px;
  width: 60%;
}

/* toggler animation */
.toggler:checked + .hamburger > div {
  transform: rotate(135deg);
  justify-content: center;
}

.toggler:checked + .hamburger > div:before,
.toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
  width: 100%;
}

/* menu */
.mobile-nav--menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease;
}

.mobile-nav--menu > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-secondary);
  height: 100vh;
  width: 80vw;
  transform: translateX(100%);
  transition: all 0.4s ease;
}

.mobile-nav--menu li {
  margin: 6rem 0;
}

.mobile-nav--menu .btn {
  margin-top: 3rem;
}

.mobile-nav--menu .nav-btn {
  padding: 1rem 2rem;
}

.toggler:checked ~ .mobile-nav--menu {
  backdrop-filter: blur(10px);
}

.toggler:checked ~ .mobile-nav--menu > div {
  transform: translateX(0);
}

/* ----- ANIMATION ----- */
.animate-nav {
  transform: translateX(-50%);
  opacity: 0;
  animation: navigation 0.2s forwards linear;
}

.animate-nav-1 {
  animation-delay: 0.5s;
}
.animate-nav-2 {
  animation-delay: 0.7s;
}
.animate-nav-3 {
  animation-delay: 0.9s;
}
.animate-nav-4 {
  animation-delay: 1.1s;
}

@keyframes navigation {
  from {
    will-change: transform, opacity;
  }
  to {
    will-change: unset;
    transform: translateX(0);
    opacity: 1;
  }
}
