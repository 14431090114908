.card-ec {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: var(--secondary-bg-apps);
  height: 100%;
  width: 100px;
  margin: 0 1rem;
  border-radius: 50px;
  flex: 0.5;
  cursor: pointer;
  transition: flex 0.7s ease-in;
}

.card-ec h3 {
  color: var(--primary-bg-apps);
  font-size: 1.25rem;
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  margin: 0;
  opacity: 0;
}

.card-ec.active-ec {
  flex: 5;
}

.card-ec.active-ec h3 {
  opacity: 1;
}
