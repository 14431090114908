.library {
  background-color: var(--primary-bg-lib);
  width: 100vw;
  height: 100vh;
}

.app-lib {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--primary-bg-lib);
  /* height: 65vh; */
  height: auto;
  width: 100%;
  padding-top: 2rem;
}

.grid-lib {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
}
.container-lib {
  height: 100%;
  background-color: var(--primary-bg-lib);
  padding: 0 4rem;
}

.column-lib {
  height: 100%;
}

.column-left-lib {
  background-color: var(--tertiary-text-lib);
  height: 100%;
  padding: 0.5rem;
}

.column-insert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: 1px solid var(--secondary-bg-lib);
}

.book-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1165.33px;
  transition: var(--transition-time) ease;
  overflow: hidden;
}

.booklist-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.book-view {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.overlay-left {
  position: absolute;
  top: 0;
  right: 0;
  width: 42%;
  height: 100%;
  background-image: radial-gradient(445px at 80% 50%, #ffffff, #0000);
}

.overlay-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;
  height: 100%;
  background-image: radial-gradient(445px at 20% 50%, #ffffff, #0000);
}

@media screen and (max-width: 1200px) {
  .container-lib {
    background-color: var(--primary-bg-lib);
    padding: 0 1rem;
  }
}

@media screen and (max-width: 920px) {
  .app-lib {
    height: 75vh;
    background: var(--tertiary-text-lib);
  }

  .overlay-left,
  .overlay-right {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .app-lib {
    height: 75vh;
    background: var(--tertiary-text-lib);
  }

  .book-view {
    display: none;
  }

  .container-lib {
    padding: 0;
  }

  .column-lib {
    width: 100vw;
  }

  .column-left-lib {
    padding: 0;
  }

  .column-insert {
    border: none;
  }
}
