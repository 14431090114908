.skills {
  font-family: var(--primary-font-bold);
  margin-left: 12rem;
  margin-bottom: 20rem;
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
}

.skills--hidden {
  opacity: 0;
}

.skills-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.skills-line {
  display: block;
  background-color: var(--secondary-text);
  width: 250px;
  height: 0.5px;
  margin-left: 0.5rem;
  transform: translateY(-8.5px);
}

.skills h5 {
  margin-bottom: 1rem;
}

.skill-bars {
  max-width: 785px;
}

.skill-bar {
  padding-right: 1rem;
}

.skill-bar {
  display: flex;
  justify-content: space-between;
}

.skill-bar h6 {
  color: var(--primary-text);
}

.progress {
  flex-grow: 1;
  background-color: #777382;
  max-width: 85%;
  height: 20px;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
  overflow: hidden;
  border-radius: 5px;
}

.progress-percent {
  background-color: var(--highlight);
  height: 100%;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .skills {
    margin-left: 0rem;
    margin-bottom: 10rem;
  }

  .progress {
    max-width: 225px;
  }
}
