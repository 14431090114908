.project {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 15rem;
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
  padding-right: 2rem;
}

.project-l {
  max-width: 1100px;
}

.project--hidden {
  opacity: 0;
}

.project h5 {
  color: var(--highlight);
  font-family: var(--secondary-font);
  font-weight: lighter;
  font-size: 1.25rem;
  margin: 0;
}

.project-l .column-left {
  position: relative;
  grid-column: 1 / 7;
  grid-row: 1 / 2;
}

.project-l .column-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-column: 5 / 13;
  grid-row: 1 / 2;
  z-index: 1;
}

.project-l .column-right:hover {
  z-index: 10;
}

.project-l .project-img {
  width: 100%;
  max-width: 750px;
  height: auto;
  border-radius: 5px;
  background-color: var(--highlight);
}

.project-l .project-img img {
  display: block;
  max-width: 750px;
  opacity: 0.4;
  border-radius: 5px;
  transition: var(--transition-time) ease opacity;
}

.project-l .project-img img:hover {
  opacity: 1;
}

.project-l .project-img video {
  display: block;
  width: 100%;
  max-width: 750px;
  height: auto;
  opacity: 0.4;
  border-radius: 5px;
  transition: var(--transition-time) ease opacity;
}

.project-l .project-img video:hover {
  opacity: 1;
}

.project-l .project-tags ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
}

.project-l .project-tags ul li {
  color: var(--primary-text);
  font-family: var(--secondary-font);
  margin: 1rem 1.5rem;
}

.project-l .project-heading {
  text-align: left;
}

.project-l .project-content {
  position: absolute;
  bottom: 20%;
  left: 0;
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  z-index: 2;
}

.project-l .column-left .icons {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 1rem;
  color: var(--primary-text);
  z-index: 2;
}

@media screen and (max-width: 1250px) {
  .project-l .column-left .icons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .project-l {
    display: none !important;
  }
}
