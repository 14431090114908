.introduction {
  margin-bottom: 20rem;
  padding-top: 10rem;
}

.introduction h2 {
  margin-bottom: 2rem;
}

.bullet-columns {
  color: var(--primary-text);
  font-family: var(--secondary-font);
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.bullet-columns ul {
  list-style: none;
}

.bullet-columns ul li::before {
  content: '\2022';
  color: var(--highlight);
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}

.intro-content {
  max-width: 42rem;
}

/* animation */
.animate-intro {
  transform: translateY(50%);
  opacity: 0;
  animation: introduction 0.2s forwards linear;
}

.animate-intro-1 {
  animation-delay: 1.6s;
}
.animate-intro-2 {
  animation-delay: 1.8s;
}
.animate-intro-3 {
  animation-delay: 2s;
}
.animate-intro-4 {
  animation-delay: 2.2s;
}
.animate-intro-5 {
  animation-delay: 2.4s;
}

@keyframes introduction {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {
  .introduction {
    max-width: 480px;
    margin-bottom: 12rem;
    margin-top: 3rem;
  }
  .introduction h1 {
    font-size: 3rem;
  }
  .introduction h2 {
    font-size: 2rem;
  }
}
