.about {
  margin-left: 6rem;
  margin-bottom: 5rem;
  margin-right: auto;
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
}

.about--hidden {
  opacity: 0;
}

.about-content {
  display: flex;
}

.profile-text {
  max-width: 500px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.img-container {
  padding: 1rem;
}

.profile-img-bg {
  border-radius: 5px;
  background-color: var(--highlight);
}

.profile-img {
  display: block;
  width: 100%;
  max-width: 350px;
  min-width: 232px;
  height: auto;
  border-radius: 5px;
  opacity: 0.4;
  transition: var(--transition-time) ease opacity;
}

.profile-img:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .about {
    margin-left: 0;
  }
  .about-content {
    flex-direction: column;
  }
}
