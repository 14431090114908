.btn-lib {
  color: var(--tertiary-text-lib);
  background-color: var(--tertiary-bg-lib);
  font-family: var(--library-font-secondary);
  margin: 0 10px;
  padding: 0.5rem 1rem;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.25s ease all;
}

.btn-lib:hover {
  background-color: var(--tertiary-bg-hover);
  transform: scale(102%);
}

.active-lib {
  background-color: var(--active-bg-btn);
}

.active-lib:hover {
  background-color: var(--active-bg-btn);
  opacity: 0.9;
}

@media screen and (max-width: 920px) {
  .btn-lib {
    margin: 0 2px;
    margin-top: 0.5rem;
  }
}
