.contact {
  margin-right: auto;
  margin-bottom: 15rem;
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
}

.contact--hidden {
  opacity: 0;
}

.contact-content {
  display: flex;
}

.contact-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-text {
  max-width: 500px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.img-container {
  padding: 1rem;
}

.contact-img-bg {
  border-radius: 5px;
  background-color: var(--highlight);
}

.contact-img {
  display: block;
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 5px;
  opacity: 0.4;
  transition: var(--transition-time) ease opacity;
}

.contact-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-btn {
  font-size: 0.9rem;
  font-weight: 200 !important;
  padding: 1rem 2rem;
  transition: var(--transition-time) ease all;
}

@media screen and (max-width: 480px) {
  .contact {
    margin-bottom: 10rem;
  }

  .contact-content {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
