@import url('https://use.typekit.net/ilx0mlo.css');
@import url('https://use.typekit.net/tah1cwj.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500&display=swap');

@font-face {
  font-family: 'Consolas';
  font-weight: 200 !important;
  src: url(./fonts/Consolas.ttf);
}

:root {
  /* fonts */
  --primary-font: noto-sans, sans-serif;
  --secondary-font: 'Consolas', 'Roboto Mono', monospace;
  /* main palette */
  --bg-primary: #191528;
  --bg-secondary: #322a50;
  --highlight: #ff9305;
  --darklight: #1f1b15;
  --primary-text: #a09ea9;
  --secondary-text: #cac9cf;
  /* resume */
  --resume-font-main: noto-sans, sans-serif;
  --resume-font-secondary: 'Consolas', 'Roboto Mono', monospace;
  --resume-text-1: #484747;
  --resume-text-2: #dfdfe2;
  --resume-text-3: #191528;
  --resume-bg-primary: #ffffff;
  --resume-bg-secondary: #191528;
  --gradient-1: rgba(25, 21, 40, 0.7);
  --gradient-2: rgba(255, 147, 5, 0.7);
  /* library */
  --library-font-main: freight-text-pro, serif;
  --library-font-secondary: proxima-nova, sans-serif;
  --primary-bg-lib: #f4f5f6;
  --secondary-bg-lib: #ecebe9;
  --tertiary-bg-lib: #343d42;
  --tertiary-bg-hover: #5a6a72;
  --active-bg-btn: #a48666;
  --primary-text-lib: #343d42;
  --secondary-text-lib: #a48666;
  --tertiary-text-lib: #ffffff;
  /* 50 apps */
  --apps-font-primary: noto-sans, sans-serif;
  --apps-font-secondary: proxima-nova, sans-serif;
  --primary-text-apps: #7a7a7a;
  --secondary-text-apps: #4c4c4c;
  --tertiary-text-apps: #c25344;
  --primary-bg-apps: #f7f5f6;
  --secondary-bg-apps: #ffffff;
  --border-apps: #b7afac;
  --shadow-apps: #141414;
  --line-border-fill: #3498db;
  --line-border-empty: #e0e0e0;
  /* times */
  --scroll-time: 1s;
  --transition-time: 0.5s;
  --reveal-delay: 1s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--bg-primary);
}

h1 {
  color: var(--secondary-text);
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 4.5rem;
}

h2 {
  color: var(--primary-text);
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 3rem;
}

h3 {
  color: var(--secondary-text);
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 2.25rem;
}

h4 {
  color: var(--primary-text);
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0;
}

h5 {
  color: var(--secondary-text);
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1.35rem;
}

h6 {
  font-family: var(--secondary-font);
  font-size: 1rem;
  font-weight: lighter;
  color: var(--highlight);
}

p {
  color: var(--primary-text);
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

a {
  text-decoration: none;
}

.body-link {
  color: var(--highlight);
  font-weight: bold;
}
