section {
  margin-bottom: 2rem;
}

.resume h2,
.resume h4,
.resume h5,
.resume h6 {
  font-family: var(--resume-font);
  font-weight: 600;
  font-style: normal;
}

.resume h2 {
  color: var(--resume-bg-primary);
  text-align: right;
  font-family: var(--resume-font);
  font-weight: 500;
  font-style: normal;
}

.resume h4 {
  color: var(--resume-text-3);
  font-size: 1.5rem;
}

.resume h5 {
  color: var(--resume-text-3);
  font-size: 1rem;
}

.resume h6 {
  color: var(--highlight);
  font-size: 0.75rem;
}

.resume li {
  color: var(--resume-text-1);
  font-family: var(--resume-font-main);
  font-weight: 300;
  font-style: normal;
  font-size: 0.9rem;
  line-height: normal;
  padding: 0;
}

.resume p {
  color: var(--resume-text-1);
  font-family: var(--resume-font-main);
  font-weight: 300;
  font-style: normal;
  font-size: 0.9rem;
  line-height: normal;
  margin-top: 0.5rem;
}

.resume {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: var(--resume-bg-primary);
  width: 100vw;
  height: 100vh;
  padding: 0 10rem;
  color: var(--resume-text-1);
  font-family: var(--resume-font-main);
  font-style: normal;
  overflow-x: hidden;
}

/* ----- LEFT COLUMN ----- */

.resume-left {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 2rem 0 0 2rem;
}

.profile-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-info ul {
  text-align: center;
  list-style: none;
  padding: 1rem 0;
}

.contact-info ul li {
  padding: 0.25rem 0;
}

.contact-link {
  color: var(--resume-text-3);
  text-decoration: none;
}

.resume-img-container {
  padding: 1rem;
}

.resume-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top left, var(--gradient-1), var(--gradient-2));
  border-radius: 0 0 33% 0;
}
.resume-img img {
  width: 100%;
  height: auto;
  opacity: 0.5;
  object-fit: cover;
  z-index: 10;
  border-radius: 0 0 33% 0;
}

.profile-summary {
  height: 70vh;
}

.resume-btn {
  font-family: var(--resume-font-secondary);
  font-size: 0.9rem;
  font-weight: 200;
  color: var(--highlight);
  background-color: var(--resume-bg-secondary);
  opacity: 1;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
  border-radius: 3px;
  transition: 0.25s ease all;
}

.resume-btn:hover {
  color: var(--resume-text-3);
  background-color: var(--highlight);
}

/* -----  RIGHT COLUMN  ----- */
.resume-right {
  padding: 3rem;
}

.resume-header {
  padding: 2rem;
  background: linear-gradient(to top right, #0000, var(--gradient-1));
  border-radius: 0 10px 10px 0;
  margin-bottom: 2rem;
}

.section-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 55%;
  margin-bottom: 1rem;
}

.section-header .line {
  background-color: var(--resume-text-3);
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
  margin-left: 0.5rem;
}

.school {
  margin-bottom: 1rem;
}

.job {
  margin: 1rem 0;
}

/* project title */
.web-project .project-title {
  color: var(--resume-text-1);
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
}

.web-project .project-title a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 0.5rem;
}

.web-project p {
  margin-right: 4rem;
}

/* project bullet list */
.web-project .project-list {
  list-style: none;
  list-style-position: inside;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.web-project .project-list li::before {
  content: '\2022';
  color: var(--highlight);
  font-weight: bold;
  display: inline-block;
  width: 1rem;
}

/* project link icon */
.project-icon {
  color: var(--resume-text-3);
  transition: color 0.25s ease;
}

.project-icon:hover {
  color: var(--highlight);
}

/* courses */
.course,
.project-name,
.role {
  display: flex;
  justify-content: space-between;
}

.course p {
  margin: 0;
  padding-left: 1rem;
}

/* media rules */
@media print {
  @page {
    size: 210mm 297mm;
    margin: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .resume {
    grid-template-columns: 1fr 4fr;
    padding: 0;
  }

  .resume-left {
    padding-left: 0;
  }

  .resume-right {
    padding: 3rem 0;
    padding-right: 0.5rem;
  }

  .resume-btn {
    display: none;
  }

  .resume-header {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .web-project p {
    font-size: 0.8rem;
  }

  .project-icon {
    display: none !important;
  }

  .resume h2 {
    font-size: 30px;
  }

  .resume h4 {
    font-size: 1.25rem;
  }

  .resume h5 {
    font-size: 0.9rem;
  }

  .objective {
    font-size: 13px;
  }

  /* Course.js */
  .course p {
    font-size: 0.8rem;
  }
}
