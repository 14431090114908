.header-p {
  background-color: var(--primary-bg-apps);
  height: 25vh;
}

.header-p h1 {
  font-size: 2rem;
}

/* ----- NAVBAR ----- */
.navbar-p {
  height: 7vh;
  box-shadow: 0 1px 5px 2px var(--shadow-apps);
}

.navbar-top-p {
  background-color: var(--primary-text-apps);
  width: 100vw;
  height: 0.5vh;
}

.navbar-content-p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.5vh;
}

/* ----- navbar logo ----- */
.navbar-logo-p p {
  color: var(--secondary-text-apps);
  margin: 0;
}

.navbar-logo-p:hover p {
  opacity: 0.8;
}

.navbar-content-p p {
  color: var(--secondary-text-apps);
  font-family: var(--apps-font-secondary);
  font-weight: 100;
  font-style: normal;
  font-size: 1.5rem;
  padding: 0 4rem;
}

/* ----- MENU --------------- */
/* ----- selector ----- */
.apps-menu {
  width: 400px;
  padding: 0 4rem;
  z-index: 1;
}

.apps-menu-cntrl {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apps-menu-cntrl h4 {
  color: var(--secondary-text-apps);
  font-family: var(--apps-font-primary);
  font-size: 1rem;
  font-weight: 100;
  font-style: normal;
}

.apps-menu-line {
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--secondary-text-apps);
}

/* ----- dropdown ----- */
.dropdown-container {
  position: absolute;
  height: 0px;
  overflow: hidden;
  transition: height ease var(--reveal-delay), box-shadow ease var(--reveal-delay);
}

.apps-dropdown {
  background: linear-gradient(to bottom, #f7f5f666, #b7afac);
  color: var(--secondary-text-apps);
  width: 272px;
  font-family: var(--apps-font-primary);
  font-size: 0.9rem;
  list-style: none;
  padding-top: 1rem;
}

.apps-dropdown a {
  color: var(--secondary-text-apps);
  cursor: pointer;
}

.apps-dropdown-item {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px dotted var(--secondary-text-apps);
}

.apps-dropdown-item:hover {
  background-color: #b3aba8e6;
}

.apps-dropdown-item:hover .dropdown-count {
  background-color: #4c4c4c80;
}

.dropdown-count {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: middle;
  width: 23px;
  height: 23px;
  color: var(--primary-bg-apps);
  background-color: #7a7a7a80;
  font-size: 0.75rem;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.coming-soon {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.coming-soon span:last-child {
  font-size: 12px;
  font-style: italic;
  text-align: end;
  align-self: flex-end;
}

/* ----- banner ----- */
.banner-p {
  position: relative;
  height: 18vh;
  background-image: url('../../../public/img/keyboard.jpg');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.overlay-p {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2rem;
  background: #4c4c4c99;
}

.banner-p h1 {
  color: var(--tertiary-text-lib);
  font-family: var(--apps-font-primary);
  font-weight: 200;
  font-style: normal;
  letter-spacing: 1px;
  padding: 0.5rem 0;
}

@media screen and (max-width: 480px) {
  .navbar-p {
    height: 5vh;
  }

  .navbar-content-p {
    height: 4.5vh;
  }
  .navbar-content-p p {
    font-size: 1.25rem;
    padding: 0 1rem;
  }

  .apps-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    padding-right: 1rem;
  }

  .apps-menu-cntrl h4 {
    font-size: 0.9rem;
    padding: 0 0.5rem;
  }

  .dropdown-container {
    transform: translateY(22px);
  }
}
