.scroll-btn {
  position: fixed;
  bottom: 15px;
  right: 20px;
  height: 60px;
  width: 60px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #96939f;
  color: var(--bg-primary);
  cursor: pointer;
  opacity: 0.75;
  z-index: 100;
  transition: all var(--scroll-time) ease;
}

.scroll-btn:hover {
  animation: none;
  background-color: var(--highlight);
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .scroll-btn {
    display: none;
  }
}
