@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.container-pro {
  background-color: var(--primary-bg-apps);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 75vh;
}

.progress-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 350px;
  z-index: 1;
}

.progress-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--line-border-empty);
  width: 100%;
  height: 4px;
  z-index: -1;
}

.line-pro {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--line-border-fill);
  width: 0;
  height: 4px;
  z-index: -1;
  transition: 0.4s ease;
}

.step-pro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Muli', sans-serif;
  background-color: var(--secondary-bg-apps);
  color: #999;
  width: 30px;
  height: 30px;
  border: 3px solid var(--line-border-empty);
  border-radius: 50%;
  z-index: 1;
  transition: 0.4s ease;
}

.step-pro.active-pro {
  border-color: var(--line-border-fill);
}

.btn-container {
  margin-top: 25px;
}

.btn-pro {
  font-family: 'Muli', sans-serif;
  font-size: 14px;
  color: var(--secondary-bg-apps);
  background-color: var(--line-border-fill);
  padding: 8px 30px;
  margin: 0 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.btn-pro:active {
  transform: scale(0.98);
}

.btn-pro:hover {
  background-color: #3084bd;
}

.btn-pro:disabled {
  background-color: var(--line-border-empty);
  cursor: not-allowed;
}

@media screen and (max-width: 920px) {
  .container-pro {
    padding: 0 1.5rem;
  }

  .progress-container {
    width: 350px;
    max-width: 100%;
  }
}
