.app-grid {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-rows: auto;
}

.app-grid--mobile-nav-open {
  height: 100vh;
  overflow: hidden;
}

.main-content {
  padding-left: 13rem;
}

@media screen and (max-width: 1250px) {
  .main-content {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .app-grid {
    grid-template-columns: 1fr;
  }

  .main-content {
    padding-left: 0;
    padding: 0 2rem;
    width: 100vw;
  }
}
