.projects {
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
}

.projects--hidden {
  opacity: 0;
}

@media screen and (max-width: 480px) {
  .projects {
    margin-bottom: 10rem;
  }
}
