.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.line {
  background-color: var(--secondary-text);
  width: 250px;
  height: 0.5px;
  transform: translateY(-50%);
  margin-left: 0.5rem;
}
