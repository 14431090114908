.resume-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.icon {
  color: var(--primary-text);
  font-size: 1.5rem;
  padding: 1rem;
  transition: 0.25s ease-in-out all;
}

.icon:hover {
  color: var(--highlight);
  transform: translateY(-5%);
}

.line-vertical {
  background-color: var(--primary-text);
  width: 2px;
  height: 100px;
  margin-top: 2rem;
}

.top {
  height: 50px;
  margin-bottom: 2rem;
}
