.book-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--tertiary-text-lib);
  padding: 1rem;
}

.book-info {
  max-width: 600px;
}
.book-btns {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  height: 100%;
  margin-bottom: 1rem;
}

.btn-book {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--primary-bg-lib);
  background-color: var(--active-bg-btn);
  font-size: 25px;
  margin: 0 10px;
  padding: 0.75rem;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.25s ease all;
}

.btn-book:hover {
  opacity: 0.9;
}

.book-title-h2 {
  color: var(--tertiary-text-lib);
  background-color: var(--tertiary-bg-lib);
  position: absolute;
  top: -10%;
  right: 50%;
  text-align: center;
  padding: 0.5rem 2rem;
  transform: translateX(50%);
}

.book-details h2 {
  font-family: var(--library-font-main);
  font-size: 1.3rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 1rem;
}

.book-details p {
  font-family: var(--library-font-main);
  font-size: 1rem;
  color: var(--primary-text-lib);
  max-width: 650px;
  padding-top: 3rem;
  margin: 0 1rem;
}

@media screen and (max-width: 1200px) {
  .book-title-h2 {
    top: 0;
    width: 75%;
  }
}

@media screen and (max-width: 1100px) {
  .book-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  .btn-book {
    margin: 0.5rem 10px;
    padding: 0.75rem;
  }
}

@media screen and (max-width: 920px) {
  .book-details {
    padding: 0 1rem 0.5rem 1rem;
  }

  .book-title-h2 {
    top: 0;
    width: 100%;
    color: var(--primary-text-lib);
    background-color: #0000;
  }
}
