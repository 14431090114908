.btn-carousel {
  color: var(--tertiary-text-lib);
  background-color: var(--active-bg-btn);
  opacity: 0.5;
  font-size: 1.5rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  border: none;
  cursor: pointer;
  z-index: 10;
  user-select: none;
  transition: var(--transition-time) ease;
}

.btn-carousel:hover {
  opacity: 0.6;
}

.prev {
  color: var(--tertiary-text-lib);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 0 20px 20px 0;
}

.next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 20px 0 0 20px;
}

.icon-lib {
  transition: var(--transition-time) ease;
}

.btn-carousel:hover .icon-lib {
  transform: scale(110%);
}

.icon-left {
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.icon-right {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

@media screen and (max-width: 920px) {
  .btn-carousel {
    opacity: 1 !important;
  }
}
