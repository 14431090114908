.slide-lib {
  display: inline-block;
}

.card-lib {
  background-color: var(--secondary-bg-lib);
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 275px;
  padding: 15px;
  margin: auto 10px;
  border: 1px solid var(--secondary-text-lib);
}
.image-lib {
  height: 196px;
  overflow: hidden;
  cursor: pointer;
}
.content-lib {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.5rem;
  white-space: normal;
}

.book-card {
  max-width: 125px;
}

.book-title {
  color: var(--primary-text-lib);
  font-family: var(--library-font-secondary);
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-size: 14px;
}

.book-year {
  font-size: 10px;
}

.book-author {
  color: var(--secondary-text-lib);
  font-family: var(--library-font-secondary);
  font-size: 13px;
}
