.default-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
  width: 100vw;
  height: 75vh;
  background-color: var(--primary-bg-apps);
}

.content-container {
  background-color: var(--secondary-bg-apps);
  max-width: 950px;
  padding: 0.5rem;
}

.content-border {
  background-color: var(--secondary-bg-apps);
  width: 100%;
  border: 1px solid var(--border-apps);
}

.default-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-content h3 {
  color: var(--secondary-text-apps);
  margin-bottom: 2rem;
}
.default-content p {
  color: var(--primary-text-apps);
}

.default-column-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  flex-shrink: 1;
  padding: 0.5rem;
  background-color: var(--secondary-bg-apps);
}

.default-column-left img {
  max-width: 100%;
  height: auto;
  min-height: 0;
}

.default-column-right {
  padding: 0 2rem;
  height: 100%;
  flex-shrink: 2;
}

.default-col-r-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.default-col-r-content a {
  color: var(--tertiary-text-apps);
  text-decoration: none;
}
