.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 0.75rem;
  color: var(--primary-text);
  line-height: 1.8;
  padding-bottom: 1rem;
}

.footer a {
  color: var(--primary-text);
  text-decoration: none;
}

.footer-item-2 {
  font-size: x-small;
}
