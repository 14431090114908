.project {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 15rem;
  transition: transform var(--reveal-delay), opacity var(--reveal-delay);
}

.project-r {
  max-width: 1100px;
}

.project--hidden {
  opacity: 0;
}

.project h5 {
  color: var(--highlight);
  font-family: var(--secondary-font);
  font-weight: lighter;
  font-size: 1.25rem;
  margin: 0;
}

.project-r .column-left {
  grid-column: 1 / 9;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.project-r .column-left:hover {
  z-index: 10;
}

.project-r .column-right {
  position: relative;
  grid-column: 7 / 13;
  grid-row: 1 / 2;
  z-index: 2;
}

.project-r .project-img {
  width: 100%;
  max-width: 750px;
  height: auto;
  border-radius: 5px;
  background-color: var(--highlight);
}

.project-r .project-img:hover .column-left {
  z-index: 10;
}

.project-r .project-img img {
  display: block;
  max-width: 750px;
  opacity: 0.4;
  border-radius: 5px;
  transition: var(--transition-time) ease opacity;
}

.project-r .project-img img:hover {
  opacity: 1;
}

.project-r .project-img video {
  display: block;
  width: 100%;
  max-width: 750px;
  height: auto;
  opacity: 0.4;
  border-radius: 5px;
  transition: var(--transition-time) ease opacity;
}

.project-r .project-img video:hover {
  opacity: 1;
}

.project-r .project-tags ul {
  display: flex;
  list-style: none;
}

.project-r .project-tags ul li {
  color: var(--primary-text);
  font-family: var(--secondary-font);
  margin: 1rem 1.5rem;
}

.project-r .project-heading {
  position: relative;
  text-align: right;
  z-index: 2;
}

.project-r .project-content {
  position: absolute;
  bottom: 20%;
  right: 0;
  min-width: 450px;
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  z-index: 2;
}

.project-r .column-right .icons {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 1rem;
  color: var(--primary-text);
  z-index: 2;
}

/* mobile project card*/
.project-mobile {
  background-color: var(--bg-secondary);
  padding: 2rem 1rem;
  margin-bottom: 2rem;
}

.project-mobile h4 {
  color: var(--highlight);
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.project-mobile h5 {
  color: var(--highlight);
  font-family: var(--secondary-font);
  font-weight: lighter;
  font-size: 1rem;
  margin: 0;
}

.project-mobile .project-tags ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.project-mobile .project-tags ul li {
  color: var(--primary-text);
  font-family: var(--secondary-font);
  font-size: smaller;
  margin: 1rem 0.25rem;
}

.project-mobile .mobile-icons {
  display: flex;
}

@media screen and (max-width: 1250px) {
  .project-r .column-right .icons {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .project-img {
    display: none;
  }
}
