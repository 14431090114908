.sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  grid-row: span 2;
}

.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.icon {
  color: var(--primary-text);
  font-size: 1.5rem;
  padding: 1rem;
  transition: 0.25s ease-in-out all;
}

.icon:hover {
  color: var(--highlight);
  transform: translateY(-5%);
}

.icon-react {
  color: var(--bg-primary);
  background-color: var(--primary-text);
  font-size: 1.5rem;
  border: none;
}

.line-vertical {
  background-color: var(--primary-text);
  width: 2px;
  height: 100px;
  margin-top: 2rem;
}

.top {
  height: 50px;
  margin-bottom: 2rem;
}

/* animation */
.animate-sidebar {
  opacity: 0;
}

.animate-sidebar-1 {
  animation: sidebar-top 0.25s forwards linear;
  animation-delay: 2.9s;
}

.animate-sidebar-2 {
  transform: translateY(100%);
  animation: sidebar-btm 0.25s forwards linear;
  animation-delay: 2.9s;
}

@keyframes sidebar-top {
  to {
    opacity: 1;
  }
}
@keyframes sidebar-btm {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .sidebar {
    display: none;
  }
}
