.shelves {
  font-family: var(--library-font-secondary);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 1.5em;
}

@media screen and (max-width: 920px) {
  .shelves {
    padding: 0;
  }
}
