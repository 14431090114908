.list-lib {
  position: relative;
  width: 175px;
  padding: 10px;
  text-align: center;
  transition: var(--transition-time) ease;
}

.carousel {
  position: relative;
  white-space: nowrap;
}

.carousel > * {
  vertical-align: middle;
  transition: var(--transition-time) ease;
}

.carousel-overlay {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
